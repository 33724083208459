// 
// _table.scss
// 

.table {
  th {
      font-weight: 700;
      color: #9FA2B4;
      font-family: 'Mulish', sans-serif !important;
      font-size: 14px !important;
  }
}

tr {
  height: 65px !important;
  cursor: pointer;
}

tr td {
  font-size: 14px !important;
  color: #252733;
  font-weight: 600;
}

tr td:last-child {
  font-size: 11px !important;
  font-weight: 700;
}

//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}
