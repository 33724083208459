.login-head {
  text-align: center;
  margin-top: 30px;
}

.login-head h2 {
  font-size: 20px;
  font-weight: 700;
  color: #A4A6B3;
  opacity: 0.7;
}

.login-head h3 {
  font-size: 20px;
  color: #252733;
  font-weight: 700;
}

.login-head p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #9FA2B4;
}

.input-label {
  font-size: 12px;
  font-weight: 700;
  color: #9FA2B4;
}

input {
  border: 1px solid #F0F1F7 !important;
  background-color: #FCFDFE !important;
  height: 42px;
}

.forgot-password {
  font-size: 10px;
  font-weight: 400;
  float: right;
  margin-left: 140px;
}

.forgot-password:hover {
  cursor: pointer;
}

.login-btn {
  background-color: #D97706;
  height: 48px;
  color: #fff;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 4px 12px 0px #3751FF3D;
}

.login-btn:hover {
  filter: brightness(90%);
}