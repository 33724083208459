.dashboard-head {
  color: #059669;
  font-weight: 600;
  font-size: 24px;
}

.head {
  color: #656565;
  font-weight: 500;
  font-size: 16px;
}

.user-number {
  color: #2B2828;
  font-size: 18px;
  font-weight: 600;
}

.users {
  margin-top: 80px;
}
.user-loans {
  color: #525252;
  font-size: 14px;
}
.user-week {
  color: #131212;
  font-weight: 700;
  font-size: 12px;
}
.card-height {
  min-height: 140px;
  border-radius: 8px !important;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

input {
  border: 1px solid #C2C2C2 !important;
  background-color: #FFFFFF !important;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  padding: 13px;
}

.package {
  padding: 6px 20px;
  border-radius: 10px;
  font-weight: 600;
}
.total {
  color: #000000;
  background-color: #0000001a;
}
.pending {
  color: #FEC400;
  background-color: #fec30044;
}
.accepted {
  color: #86F599;
  background-color: #86f59938;
}
.transit {
  color: #C9DA1D;
  background-color: #cada1d46;
}
.completed {
  color: #059669;
  background-color: #0596682d;
}
.cancelled {
  color: #DC1515;
  background-color: #dc15152c;
}

.social-btn {
  background-color: #e4e4e5;
  color: #242424;
  font-weight: 500;
  border-radius: 28px;
  border: 0;
}