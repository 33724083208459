.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  opacity: 0.7;
}

.skeleton-search {
  width: 100%;
  height: 35px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.skeleton-head {
  height: 25px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.skeleton-p {
  height: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.skel {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  background-color: hsl(200, 13%, 91%);
}

.skeleton-circle {
  position: absolute;
  top: 50px;
  left: 50px;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(199, 18%, 83%);
  }
  100% {
    background-color: hsl(200, 11%, 95%);
  }
}

.title-header {
  color: #252733;
  font-size: 20px;
  font-weight: 700;
}

.page-item.active .page-link {
  background-color: #059669 !important;
  color: #fff !important;
}

.page-link {
  color: #d97706 !important;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  color: #059669 !important;
}

.sort {
  cursor: pointer;
}

.sort:hover {
  color: #d97706;
}

.card {
  border-radius: 10px !important;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 15px;
}

.dropdown-toggle:hover {
  color: #f7f7f7;
}

.tab-btn {
  display: flex;
  justify-content: center;
  width: fit-content;
  /* height: 38px; */
  border-radius: 6.7px;
  border: 1px solid #f5f5f5;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 160%;
  padding: 10px 25px;
  color: #059669;
  cursor: pointer;
}

.active-tab {
  background-color: #059669 !important;
  color: #fff !important;
}

.text-green {
  color: #059669;
}

.details-row {
  background-color: #fafafa;
  margin: 9px 16px;
  padding: 10px;
  border-radius: 8px;
}

.details-name {
  color: #9aa3b0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  margin-bottom: 6px;
}

.details-info {
  color: #e87000;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}
